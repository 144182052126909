import { Card, CardContent, Skeleton, Stack } from "@mui/material";

export function FacilityCardGhost() {
  return (
    <Card
      sx={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "8px",
      }}
    >
      <CardContent sx={{ "&:last-child": { padding: 0 }, padding: 0 }}>
        <Stack justifyContent="space-between" alignItems="center" gap={3}>
          <Skeleton
            variant="text"
            sx={{
              width: `${Math.floor(Math.random() * 65) + 35}%`,
              fontSize: "16px",
              fontWeight: "600",
              alignSelf: "flex-start",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: "64px",
              height: "32px",
              width: "100px",
              alignSelf: "flex-end",
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
