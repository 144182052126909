import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { type QualificationsResponse, qualificationsResponseSchema } from "../types";

export interface GetQualificationsParams {
  [key: string]: boolean | undefined;
  enabled?: boolean;
}

export function useGetQualifications(
  params: GetQualificationsParams,
  options: UseGetQueryOptions<QualificationsResponse> = {}
): UseQueryResult<QualificationsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/qualifications`,
    requestSchema: z.object({ enabled: z.boolean().optional() }),
    responseSchema: qualificationsResponseSchema,
    queryParams: params,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_QUALIFICATIONS_FAILURE,
      userErrorMessage: "Something went wrong while loading qualifications",
    },
    ...options,
  });
}
