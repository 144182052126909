import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, type MaxReferralBonusConfig, useCbhFlags } from "@src/appV2/FeatureFlags";

import { DEFAULT_MAX_REFERRAL_BONUS_AMOUNT_IN_DOLLARS } from "./constants";

export function useMaxReferralBonusAmount(type: keyof MaxReferralBonusConfig): number {
  const ldFlags = useCbhFlags();

  const maxReferralBonusAmount = ldFlags[CbhFeatureFlag.MAX_REFERRAL_BONUS_CONFIG]?.[type];

  if (
    !isDefined(maxReferralBonusAmount) ||
    !Number.isFinite(maxReferralBonusAmount) ||
    maxReferralBonusAmount <= 0
  ) {
    return DEFAULT_MAX_REFERRAL_BONUS_AMOUNT_IN_DOLLARS;
  }

  return maxReferralBonusAmount;
}
