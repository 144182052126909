import { isDefined } from "@clipboard-health/util-ts";
import { ReferralBonusTypes } from "@src/appV2/Accounts/WorkerReferrals/types";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const responseSchema = z.object({
  data: z.array(
    z.object({
      attributes: z.object({
        referralProgram: z.nativeEnum(ReferralBonusTypes),
        eligible: z.boolean(),
      }),
    })
  ),
});

export type GetWorkerEligibilityResponse = z.infer<typeof responseSchema>;

export function getReferralsEligibilityUrl(workerId?: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/referrals-eligibility/${workerId ?? ""}`;
}

export function useReferralsEligibility(
  params: { workerId?: string },
  options: UseGetQueryOptions<GetWorkerEligibilityResponse> = {}
): UseQueryResult<GetWorkerEligibilityResponse | undefined> {
  const { workerId } = params;
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/referrals-eligibility/${workerId ?? ""}`,
    responseSchema,
    enabled: isDefined(workerId),
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_REFERRALS_ELIGIBILITY_FAILURE,
    },
    ...options,
  });
}
