import { toSearchParams } from "@clipboard-health/json-api";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const ranking = "ranking";
const workplace = "workplace";
const rankingType = z.literal(ranking);
const workplaceType = z.literal(workplace);

const validIncludeValues = [workplace] as const;

const WorkplaceRelationshipsSchema = z.object({
  data: z.object({
    type: workplaceType,
    id: z.string(),
  }),
});

const GetRankingsAttributesSchema = z.object({
  workerType: z.string().optional(),
  rank: z.number().optional(),
  isOnATeam: z.boolean().optional(),
  updatedAt: z.string().datetime().optional(),
  aTeamRosterSize: z.number().optional(),
});

export type GetRankingsAttributesType = z.infer<typeof GetRankingsAttributesSchema>;

const GetRankingsRelationshipsSchema = z.object({
  workplace: WorkplaceRelationshipsSchema.optional(),
});

const WorkplaceAttributesSchema = z.object({
  name: z.string().optional(),
});

const WorkplaceSchema = z.object({
  type: workplaceType,
  id: z.string(),
  attributes: WorkplaceAttributesSchema,
});

const IncludedSchema = WorkplaceSchema;

export type IncludedType = z.infer<typeof IncludedSchema>;

const GetRankingsIncludedSchema = z.array(IncludedSchema).optional();

export type GetRankingsIncludedType = z.infer<typeof GetRankingsIncludedSchema>;

const LinksSchema = z.object({
  next: z.string().optional(),
  prev: z.string().optional(),
});

const GetRankingsDataSchema = z.array(
  z.object({
    type: rankingType,
    id: z.string(),
    attributes: GetRankingsAttributesSchema,
    relationships: GetRankingsRelationshipsSchema.optional(),
  })
);

export type GetRankingsDataType = z.infer<typeof GetRankingsDataSchema>;

export const GetRankingsResponseSchema = z.object({
  data: GetRankingsDataSchema,
  included: GetRankingsIncludedSchema,
  links: LinksSchema.optional(),
});

export type GetWorkerRankingsResponseType = z.infer<typeof GetRankingsResponseSchema>;

export const GetWorkerRankingsQuerySchema = z.object({
  filter: z
    .object({
      isOnATeam: z.boolean().optional(),
    })
    .optional(),
  fields: z
    .object({
      ranking: z
        .array(z.enum(["workerType", "isOnATeam", "workplace.id", "updatedAt", "aTeamRosterSize"]))
        .optional(),
      workplace: z.array(z.enum(["name"])).optional(),
    })
    .optional(),
  include: z.array(z.enum([...validIncludeValues])).optional(),
  page: z
    .object({
      size: z.number().optional(),
      cursor: z.string().optional(),
    })
    .optional(),
});

export type GetWorkerRankingsQuery = z.infer<typeof GetWorkerRankingsQuerySchema>;

export function useGetWorkerRankings(
  query: GetWorkerRankingsQuery,
  options: UseGetQueryOptions<GetWorkerRankingsResponseType> = {}
): UseQueryResult<GetWorkerRankingsResponseType> {
  const worker = useDefinedWorker();

  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${
      worker.userId
    }/rankings?${toSearchParams(query).toString()}`,
    responseSchema: GetRankingsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_RANKINGS_FAILURE,
    },
    ...options,
    enabled: worker.userId.length > 0 && options.enabled !== false,
  });
}
