import { isDefined } from "@clipboard-health/util-ts";

import { type GetWorkerRankingsQuery, useGetWorkerRankings } from "./api/useGetWorkerRankings";
import { useATeamStatus } from "./useATeamStatus";
import { workerRankingsByWorkplaces } from "./utils";

const MAX_PAGE_SIZE = 200;

export function useGetATeamWorkplaces() {
  const { isWorkerAteamEnabled, aTeamStatus } = useATeamStatus();

  const query: GetWorkerRankingsQuery = {
    include: ["workplace"],
    fields: {
      ranking: ["isOnATeam", "workerType", "updatedAt", "workplace.id", "aTeamRosterSize"],
      workplace: ["name"],
    },
    filter: {
      isOnATeam: true,
    },
    page: {
      size: MAX_PAGE_SIZE,
    },
  };

  const {
    data: workerRankings,
    isLoading,
    refetch: refetchATeamWorkplaces,
  } = useGetWorkerRankings(query, {
    enabled: isWorkerAteamEnabled,
  });

  if (!isWorkerAteamEnabled) {
    return {
      aTeamWorkplaces: {},
      isWorkerAteamEnabled: false,
      aTeamStatus,
      aTeamWorkplacesCount: 0,
    };
  }

  const aTeamWorkplaces = isDefined(workerRankings)
    ? workerRankingsByWorkplaces(workerRankings)
    : {};

  return {
    aTeamWorkplaces,
    isWorkerAteamEnabled,
    aTeamStatus,
    aTeamWorkplacesCount: Object.keys(aTeamWorkplaces).length,
    isATeamWorkplaceLoading: isLoading,
    refetchATeamWorkplaces,
  };
}
