import { isDefined } from "@clipboard-health/util-ts";
import { max, parseISO } from "date-fns";

import type { GetWorkerRankingsResponseType } from "./api/useGetWorkerRankings";
import type { ATeamWorkplaces } from "./types";

export function workerRankingsByWorkplaces(
  workerRankings: GetWorkerRankingsResponseType
): ATeamWorkplaces {
  return (workerRankings?.data ?? []).reduce<ATeamWorkplaces>((accumulator, ranking) => {
    const workplace = workerRankings?.included?.find(
      (included) =>
        included.type === "workplace" && included.id === ranking.relationships?.workplace?.data.id
    );
    const workplaceId = workplace?.id;
    if (isDefined(workplaceId) && isDefined(ranking.attributes.workerType)) {
      if (accumulator[workplaceId]) {
        accumulator[workplaceId].workerTypes.push(ranking.attributes.workerType);
        accumulator[workplaceId].recentUpdatedAt = isDefined(ranking.attributes.updatedAt)
          ? max([
              accumulator[workplaceId].recentUpdatedAt ?? new Date(0),
              parseISO(ranking.attributes.updatedAt),
            ])
          : accumulator[workplaceId].recentUpdatedAt;
      } else {
        accumulator[workplaceId] = {
          name: workplace?.attributes.name ?? "",
          id: workplaceId,
          workerTypes: [ranking.attributes.workerType],
          aTeamRosterSize: ranking.attributes.aTeamRosterSize,
          recentUpdatedAt: isDefined(ranking.attributes.updatedAt)
            ? parseISO(ranking.attributes.updatedAt)
            : undefined,
        };
      }
    }

    return accumulator;
  }, {});
}
