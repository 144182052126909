import { CbhFeatureFlag } from "../FeatureFlags/CbhFeatureFlag";
import { FEATURE_FLAG_DEFAULT_VALUES } from "../FeatureFlags/constants";
import { useCbhFlag } from "../FeatureFlags/useCbhFlag";
import { ATeamHcpStatus } from "./types";

export function useATeamStatus() {
  const { status: aTeamStatus } = useCbhFlag(CbhFeatureFlag.A_TEAM_HCP, {
    defaultValue: FEATURE_FLAG_DEFAULT_VALUES[CbhFeatureFlag.A_TEAM_HCP],
  });
  const isWorkerAteamEnabled = [ATeamHcpStatus.A_TEAM, ATeamHcpStatus.PRIORITY_ACCESS].includes(
    aTeamStatus
  );

  return {
    isWorkerAteamEnabled,
    aTeamStatus,
  };
}
